import React from "react";

const Features = () => {
  return (
    <>
      <div className="overflow-hidden">
        <section id="about" className="about-us ptb-100 background-shape-img">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="about-content-left section-heading">
                  <h2>What is Dispatch Guard Report?</h2>
                  <h5>
                    We provide platform for North American carriers to file a
                    report against a load broker if:
                  </h5>
                  <div className="single-feature mb-4 mt-5">
                    <div className="icon-box-wrap d-flex align-items-center mb-2">
                      <div
                        className="mr-3 icon-box"
                        style={{
                          height: "40px",
                          width: "40px",
                          padding: "7px",
                        }}
                      >
                        <img
                          src="assets/img/image-icon-1.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">Carrier is not paid as promised.</p>
                    </div>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div
                        className="mr-3 icon-box"
                        style={{
                          height: "40px",
                          width: "40px",
                          padding: "7px",
                        }}
                      >
                        <img
                          src="assets/img/image-icon-1.png"
                          alt="icon "
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                        Broker cancelled load last minute and not paying TONU.
                      </p>
                    </div>
                    <p></p>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div
                        className="mr-3 icon-box"
                        style={{
                          height: "40px",
                          width: "40px",
                          padding: "7px",
                        }}
                      >
                        <img
                          src="assets/img/image-icon-1.png"
                          alt="icon "
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                        Broker is holding trailer for long period of time and
                        not paying.
                      </p>
                    </div>
                    <p></p>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div
                        className="mr-3 icon-box"
                        style={{
                          height: "40px",
                          width: "40px",
                          padding: "7px",
                        }}
                      >
                        <img
                          src="assets/img/image-icon-1.png"
                          alt="icon "
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                        Carrier is experiencing difficulty with clear
                        communication.
                      </p>
                    </div>
                    <p></p>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div
                        className="mr-3 icon-box"
                        style={{
                          height: "40px",
                          width: "40px",
                          padding: "7px",
                        }}
                      >
                        <img
                          src="assets/img/image-icon-1.png"
                          alt="icon "
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                        Broker is filing false claim against carrier & no
                        communication.
                      </p>
                    </div>
                    <p></p>
                  </div>
                </div>
                <div>
                  <p>
                    If you are experiencing all of these issues with your load
                    broker please file a complaint with LoadBroker411.com today
                    and we will help you resolve and get your hard earned money.
                  </p>
                  <a
                    href="https://app.loadbroker411.com/complaint/new"
                    className="btn solid-btn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    File a Complaint
                  </a>
                </div>
              </div>
              <div className="col-md-5">
                <div className="about-content-right">
                  <img
                    src="assets/img/dispatchguard-1.png"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Features;
