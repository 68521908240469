import React from "react";

const FileComplaint = () => {
  return (
    <>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-8">
              <div className="section-heading text-center mb-5">
                <h2>File a Complaint</h2>
                <p className="lead">
                  LoadBroker411.com welcomes the opportunity to assist you with
                  your complaint. Weather you did not got paid as promised,
                  cancel load without reason, detention not paid as promised,
                  equipment held hostage, no response to the calls or any other
                  reason that you are loosing revenue. Here at LoadBroker411 we
                  want to make sure that your voice is heard and reach to proper
                  sources and bring awareness to the carrier community so the no
                  one else loose revenue.
                </p>
              </div>
              <div className="col-md-12">
                <div className="about-content-left section-heading">
                  <div className="single-feature mb-4 mt-5">
                    <div className="icon-box-wrap d-flex align-items-center mb-2">
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                      <p className="mb-0">
                        I’d like to file complaint against load broker and have
                        LoadBroker411 help me resolve my issue.
                      </p>
                      <a
                        href="https://app.loadbroker411.com/complaint/new"
                        className="btn outline-btn"
                        target="_blank"
                        rel="noreferrer"
                      >
                        File Complaint <span className="ti-arrow-right"></span>
                      </a>
                    </div>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                      <p className="mb-0">
                        I’d like to share my experience about a load broker.
                      </p>
                      <a
                        href="https://app.loadbroker411.com"
                        className="btn outline-btn"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Write a Review <span className="ti-arrow-right"></span>
                      </a>
                    </div>
                    <p></p>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                      <p className="mb-0">
                        I want to warn others about a load broker that commit
                        fraud.
                      </p>
                      <a
                        href="https://app.loadbroker411.com"
                        className="btn outline-btn"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Report a Fraud <span className="ti-arrow-right"></span>
                      </a>
                    </div>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row equal">
            <div className="col-md-4 col-lg-4">
              <div
                className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                style={{ backgroundColor: "F2EAFC" }}
              >
                <div className="">
                  <img
                    src="assets/img/shield-search.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                    style={{ height: "60px", width: "80px" }}
                  />
                </div>
                <h5>Easy Broker Search</h5>
                <p>
                  Search brokers for non payment, holding equipment, not paying
                  TONU, detention time and much more.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="">
                  <img
                    src="assets/img/shield-guard.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                    style={{ height: "60px", width: "80px" }}
                  />
                </div>
                <h5>Dispatch Guard Report</h5>
                <p>
                  File or Search Dispatch Guard report file against brokers.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="">
                  <img
                    src="assets/img/shield-dollar.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                    style={{ height: "60px", width: "80px" }}
                  />
                </div>
                <h5>Debt. Collection Resources</h5>
                <p>
                  We help connecting industry experts in transportation debt
                  collection and monitor the end results.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="">
                  <img
                    src="assets/img/shield-eye.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                    style={{ height: "60px", width: "80px" }}
                  />
                </div>
                <h5>Resolve & Monitor Cases</h5>
                <p>
                  We help resolve cases and monitor them for the positive or
                  negative outcome.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="">
                  <img
                    src="assets/img/shield-lock.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                    style={{ height: "60px", width: "80px" }}
                  />
                </div>
                <h5>Secure Data</h5>
                <p>
                  Rest assured, your data is secured with us and we do not sell
                  our date to any third party.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="">
                  <img
                    src="assets/img/shield-tick.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                    style={{ height: "60px", width: "80px" }}
                  />
                </div>
                <h5>Verified Reviews</h5>
                <p>
                  Powerful & Verified reviews and reports filed against load
                  brokers..
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default FileComplaint;
