const pricingData = [
  {
    min_power_units: 1,
    max_power_units: 1,
    price: 29.99,
    type: "Owner Operator",
  },
  {
    min_power_units: 2,
    max_power_units: 10,
    price: 39.99,
    users: 2,
    type: "Basic Plan",
  },
  {
    min_power_units: 11,
    max_power_units: 20,
    price: 49.99,
    users: 3,
    type: "Plus Plan",
  },
  {
    min_power_units: 21,
    max_power_units: 50,
    price: 59.99,
    users: 4,
    type: "Pro Plan",
  },
  {
    min_power_units: 50,
    max_power_units: 999999999,
    price: 99.99,
    users: 5,
    type: "Unlimited Plan",
  },
];
const getPricingData = (powerUnits) => {
  let pricing = pricingData[0];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < pricingData.length; i++) {
    if (
      powerUnits >= pricingData[i].min_power_units &&
      powerUnits <= pricingData[i].max_power_units
    ) {
      pricing = pricingData[i];
      break;
    }
  }
  return pricing;
};
module.exports = { getPricingData };
