import React from "react";

const Promo = () => {
  return (
    <>
      <section className="promo-section ptb-100">
        <div className="container">
          <h2 style={{ textAlign: "center", paddingBottom: "10px" }}>
            Why Carriers Need LoadBroker411.com?
          </h2>
          <div
            className="row equal"
            style={{ fontSize: "1.25rem", paddingBottom: "100px" }}
          >
            <div className="col-md-5 col-lg-5">
              <div
                className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100 single-pricing-pack"
                style={{ backgroundColor: "F2EAFC" }}
              >
                <p>
                  Loadbroker411.com is a valuable platform for carriers in the
                  transportation industry. By providing a platform to rate and
                  review brokers and loading/unloading facilities, it promotes
                  transparency and accountability within the industry. Carriers
                  can share their experiences and insights to help others make
                  informed decisions about which brokers and facilities to work
                  with, leading to improved efficiency and reliability in the
                  transportation process.
                </p>
              </div>
            </div>
            <div className="col-md-7 col-lg-7">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100 single-pricing-pack">
                <p>
                  The ability to report faulty brokers, unfriendly
                  loading/unloading facilities, and truck stops is particularly
                  beneficial, as it helps warn other carriers about potential
                  issues they might encounter. This feature can prevent carriers
                  from facing unnecessary challenges and enables them to avoid
                  problematic situations. <br /> By fostering a system of shared
                  information and feedback, LoadBroker411.Com can contribute to
                  a more trustworthy and efficient transportation industry,
                  where carriers can have confidence in their business
                  partnerships and choose reliable brokers and facilities for
                  their operations.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row equal">
            <div className="col-md-4 col-lg-4">
              <div
                className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                style={{ backgroundColor: "F2EAFC" }}
              >
                <div className="">
                  <img
                    src="assets/img/shield-search.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                    style={{ height: "60px", width: "80px" }}
                  />
                  {/* <span className="ti-vector text-white"></span> */}
                </div>
                <h5>Easy Broker Search</h5>
                <p>
                  Search brokers for non payment, holding equipment, not paying
                  TONU, detention time and much more.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="">
                  <img
                    src="assets/img/shield-guard.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                    style={{ height: "60px", width: "80px" }}
                  />
                  {/* <span className="ti-shield text-white"></span> */}
                </div>
                <h5>Dispatch Guard Report</h5>
                <p>
                  File or Search Dispatch Guard report file against brokers.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="">
                  <img
                    src="assets/img/shield-dollar.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                    style={{ height: "60px", width: "80px" }}
                  />
                  {/* <span className="ti-money text-white"></span> */}
                </div>
                <h5>Debt. Collection Resources</h5>
                <p>
                  We help connecting industry experts in transportation debt
                  collection and monitor the end results.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="">
                  <img
                    src="assets/img/shield-eye.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                    style={{ height: "60px", width: "80px" }}
                  />
                  {/* <span className="ti-eye text-white"></span> */}
                </div>
                <h5>Resolve & Monitor Cases</h5>
                <p>
                  We help resolve cases and monitor them for the positive or
                  negative outcome.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="">
                  <img
                    src="assets/img/shield-lock.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                    style={{ height: "60px", width: "80px" }}
                  />
                  {/* <span className="ti-lock text-white"></span> */}
                </div>
                <h5>Secure Data</h5>
                <p>
                  Rest assured, your data is secured with us and we do not sell
                  our date to any third party.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="">
                  <img
                    src="assets/img/shield-tick.png"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                    style={{ height: "60px", width: "80px" }}
                  />
                  {/* <span className="ti-check text-white"></span> */}
                </div>
                <h5>Verified Reviews</h5>
                <p>
                  Powerful & Verified reviews and reports filed against load
                  brokers..
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
