import axios from "axios";
import React, { useState } from "react";

export default function Contact({ bgColor }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [usdot, setUsdot] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <h2>Contact Us Via Email</h2>
                <p>
                  Have questions or inquiries? We're here to help! Feel free to
                  reach out to our dedicated team for assistance.
                </p>
              </div>
              <div className="footer-address">
                {/* <h6>
                  <strong>Head Office</strong>
                </h6>
                <p>
                  100 W 30TH ST CHEYENNE <br /> WY 82001-2523 USA
                </p> */}
                <ul>
                  {/* <li>
                    <span>Phone: +1 (559) 293-8700</span>
                  </li> */}
                  <li>
                    <span>
                      Email:{" "}
                      <a href="mailto:admin@loadbroker411.com">
                        admin@loadbroker411.com
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
              {/* <form id="contactForm" className="contact-us-form"> */}
              <h5>Reach us quickly</h5>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      name="name"
                      id="name"
                      placeholder="Enter name"
                      required="required"
                      onChange={(e) => {
                        setName(e?.target?.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      name="email"
                      id="email"
                      placeholder="Enter email"
                      required="required"
                      onChange={(e) => {
                        setEmail(e?.target?.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      name="phone"
                      defaultValue=""
                      value={phone}
                      className="form-control"
                      id="phone"
                      placeholder="Your Phone"
                      onChange={(e) => {
                        setPhone(e?.target?.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      name="company"
                      defaultValue=""
                      value={company}
                      size="40"
                      className="form-control"
                      id="company"
                      placeholder="Your Company"
                      onChange={(e) => {
                        setCompany(e?.target?.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      name="usdot"
                      defaultValue=""
                      value={usdot}
                      size="40"
                      className="form-control"
                      id="usdot"
                      placeholder="Your USDOT"
                      onChange={(e) => {
                        setUsdot(e?.target?.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      value={message}
                      className="form-control"
                      rows="7"
                      cols="25"
                      placeholder="Message"
                      onChange={(e) => {
                        setMessage(e?.target?.value);
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 mt-3">
                  <button
                    disabled={loading}
                    className="btn solid-btn"
                    id="btnContactUs"
                    onClick={() => {
                      setLoading(true);
                      axios
                        .post("https://broker.upforks.com/api/contactUs", {
                          name,
                          email,
                          phone,
                          company,
                          message,
                          usdot,
                        })
                        .then(function (response) {
                          console.log(response);
                          alert("Your request has been sent successfully");
                        })
                        .catch(function (error) {
                          console.log(error);
                        })
                        .finally(function () {
                          setName("");
                          setEmail("");
                          setPhone("");
                          setCompany("");
                          setMessage("");
                          setUsdot("");
                          setLoading(false);
                        });
                    }}
                  >
                    Send Message
                  </button>
                </div>
              </div>
              {/* </form> */}
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
