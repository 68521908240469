import axios from "axios";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getPricingData } from "../../utils/pricing";

const hostname = "https://broker.upforks.com";
// const hostname = "http://localhost:4000";

export default function Price({ hasBg }) {
  const [show, setShow] = useState(false);
  const [type, setType] = useState("usdot");
  const [loading, setLoading] = useState(false);
  const [usdot, setUsdot] = useState("");
  const [data, setData] = useState(null);
  const handleClose = () => {
    setData(false);
    setShow(false);
    setUsdot("");
  };
  useEffect(() => {
    setUsdot("");
  }, [type]);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="overflow-hidden">
        <section
          id="pricing"
          className={`package-section ${
            hasBg ? "gray-light-bg" : "background-shape-right"
          } ptb-100`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>
                    Affordable Pricing and Packages <br />
                    Choose your Best One
                  </h2>
                  <p className="lead">
                    Discover the perfect solution tailored to your needs with
                    our range of affordable pricing and packages.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md">
                <div
                  className="card text-center single-pricing-pack p-5"
                  style={{ height: "560px" }}
                >
                  <h5 className="mb-2">Free Forever</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/basic.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>Pricing Calculator</li>
                      <li>Diesel Prices</li>
                      <li>Daily Updates via Interface</li>
                      <li>Weekly Reporting</li>
                      <li>Collection Support</li>
                      <li>24/7 Email Support</li>
                      <li>Monthly Newsletter</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $<span className="price font-weight-bolder">0</span>
                      </div>
                    </div>
                    <a
                      href="https://app.loadbroker411.com/register"
                      className="btn outline-btn"
                    >
                      Sign up
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div
                  className="card text-center single-pricing-pack p-5"
                  style={{ height: "560px" }}
                >
                  <h5 className="mb-2">Owner Operator</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/basic.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>1 Power Unit</li>
                      <li> 1 User Login</li>
                      <li>Daily Updates via Interface</li>
                      <li>Weekly Reporting</li>
                      <li>Collection Support</li>
                      <li>24/7 Email Support</li>
                      <li>Monthly Newsletter</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        $<span className="price font-weight-bolder">29.99</span>
                      </div>
                    </div>
                    <a
                      href="https://app.loadbroker411.com/register"
                      className="btn outline-btn"
                    >
                      Purchase now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div
                  className="card text-center popular-price single-pricing-pack p-5"
                  style={{ height: "560px" }}
                >
                  <h5 className="mb-2">Fleet Managers</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/standard.svg"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <li>2 or More Power Unit</li>
                      <li>Mutli User Logins</li>
                      <li>Weekly Reporting</li>
                      <li>Daily Updates via Interface</li>
                      <li>24/7 Email Support</li>
                      <li>Collection Support</li>
                      <li>Monthly Newsletter</li>
                    </ul>
                    <div className="py-4 border-0 pricing-header">
                      <div className="h1 text-center mb-0 color-secondary">
                        {/* $<span className="price font-weight-bolder">39.99</span> */}
                      </div>
                    </div>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header>
                        <Modal.Title>Search USDOT/MC Number</Modal.Title>
                        <span
                          className="ti-close"
                          onClick={() => {
                            handleClose();
                          }}
                        ></span>
                      </Modal.Header>
                      <Modal.Body>
                        {data?.numberOfTrucks ? (
                          <>
                            <div className="col-sm">
                              <div className="card text-center single-pricing-pack p-5">
                                <h5 className="mb-2">
                                  {getPricingData(data.numberOfTrucks).type}
                                </h5>
                                <div className="pricing-img mt-3 mb-4">
                                  <img
                                    src="assets/img/unlimited.svg"
                                    alt="pricing img"
                                    className="img-fluid"
                                  />
                                </div>

                                <div className="card-body p-0">
                                  <ul className="list-unstyled text-sm pricing-feature-list">
                                    <li>
                                      {getPricingData(data.numberOfTrucks)
                                        .max_power_units == 999999999 ? (
                                        "Unlimited power units"
                                      ) : (
                                        <>
                                          Power units upto{" "}
                                          {
                                            getPricingData(data.numberOfTrucks)
                                              .max_power_units
                                          }
                                        </>
                                      )}
                                    </li>
                                    <li>
                                      Include{" "}
                                      {
                                        getPricingData(data.numberOfTrucks)
                                          .users
                                      }{" "}
                                      user
                                      {getPricingData(data.numberOfTrucks)
                                        .users > 1
                                        ? "s"
                                        : ""}
                                    </li>
                                    <li>Fully Customization interface</li>
                                    <li>Free updated</li>
                                    <li>24/7 Phone Support</li>
                                    <li>Event Analytics</li>
                                  </ul>
                                  <div className="py-4 border-0 pricing-header">
                                    <div className="h1 text-center mb-0 color-secondary">
                                      $
                                      <span className="price font-weight-bolder">
                                        {
                                          getPricingData(data.numberOfTrucks)
                                            .price
                                        }
                                      </span>
                                    </div>
                                  </div>
                                  <Button
                                    onClick={() => {
                                      setLoading(true);
                                      axios
                                        .post(`${hostname}/api/auth/register`, {
                                          usdot,
                                          type,
                                        })
                                        .then(function (response) {
                                          console.log(response?.data?.data);
                                          window.location.href = `https://app.loadbroker411.com/register?registerFromLanding=true&data=${JSON.stringify(
                                            response?.data?.data
                                          )}`;
                                        })
                                        .catch(function (error) {
                                          console.log(error);
                                        })
                                        .finally(function () {
                                          // setUsdot("");
                                          setLoading(false);
                                        });
                                    }}
                                    className="btn outline-btn-white"
                                  >
                                    Purchase now
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                paddingLeft: "25px",
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  checked={type === "usdot"}
                                  onClick={() => {
                                    setType("usdot");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault1"
                                >
                                  USDOT Number
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault2"
                                  checked={type === "mc"}
                                  onClick={() => {
                                    setType("mc");
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  for="flexRadioDefault2"
                                >
                                  MC/MX Number
                                </label>
                              </div>
                            </div>
                            <form
                              className="form-inline mt-4 mb-4"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  flexGrow: 1,
                                }}
                                className="input-group mb-3"
                              >
                                {type === "mc" ? (
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon3"
                                    >
                                      MC-
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <input
                                  type="text"
                                  className="form-control"
                                  id="basic-url"
                                  aria-describedby="basic-addon3"
                                  autoFocus
                                  value={usdot}
                                  onChange={(e) => {
                                    setUsdot(e?.target?.value);
                                  }}
                                />
                              </div>
                              <div className="ml-3">
                                <Button
                                  className="btn solid-btn"
                                  size="lg"
                                  onClick={() => {
                                    setLoading(true);
                                    axios
                                      .post(`${hostname}/api/public/pricing`, {
                                        usdot,
                                        type,
                                      })
                                      .then(function (response) {
                                        setData(response?.data?.data);
                                      })
                                      .catch(function (error) {
                                        console.log(error);
                                      })
                                      .finally(function () {
                                        setLoading(false);
                                      });
                                  }}
                                >
                                  {loading ? (
                                    <Spinner
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                      size="sm"
                                    />
                                  ) : (
                                    <span
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                      className="ti-search"
                                    />
                                  )}
                                </Button>
                              </div>
                            </form>
                          </>
                        )}
                      </Modal.Body>
                    </Modal>

                    <Button
                      className="btn solid-btn"
                      onClick={() => {
                        window.location.href =
                          "https://app.loadbroker411.com/register";
                      }}
                    >
                      Get Pricing
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="mt-5 text-center">
              <p className="mb-2">
                If you need custom services or Need more?
                <a href="#/" className="color-secondary">
                  {" "}
                  Contact us{" "}
                </a>
              </p>
            </div> */}
          </div>
        </section>
      </div>
    </>
  );
}
