import React from "react";

export default function FeatureImgThree() {
  return (
    <>
      <section id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-4">
              <div className="download-img">
                <img
                  src="assets/img/SignIn.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="feature-contents section-heading">
                <h2>Easy Process with Best Features</h2>
                <p>
                  We're thrilled to have you as part of our growing community.
                  As you embark on your journey with us, we want to ensure that
                  your onboarding experience is both delightful and effortless.
                  To make this happen, we've designed an easy onboarding process
                  that comes packed with exciting features to help you get
                  started smoothly and quickly.
                </p>

                <div className="feature-content-wrap">
                  <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active h6"
                        href="#tab6-1"
                        data-toggle="tab"
                      >
                        <span className="ti-palette"></span>
                        Enter USDOT
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-2"
                        data-toggle="tab"
                      >
                        <span className="ti-vector"></span>
                        Verify Email
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-3"
                        data-toggle="tab"
                      >
                        <span className="ti-bar-chart"></span>
                        Review Profile
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link h6"
                        href="#tab6-4"
                        data-toggle="tab"
                      >
                        <span className="ti-announcement"></span>
                        Enter Password
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content feature-tab-content">
                    <div className="tab-pane active" id="tab6-1">
                      <ul className="list-unstyled">
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">Easy search to find broker</p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">Enter USDOT number</p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">Click on Create Account</p>
                            </div>
                          </div>
                        </li>
                        {/* <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                Use Gulp to prepare all assets for production
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="badge badge-circle badge-primary mr-3">
                                <span className="ti-check"></span>
                              </div>
                            </div>
                            <div>
                              <p className="mb-0">
                                Collaboratively predominate vertical
                                manufactured
                              </p>
                            </div>
                          </div>
                        </li> */}
                      </ul>
                    </div>
                    <div className="tab-pane" id="tab6-2">
                      <div className="single-feature">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-microphone rounded mr-3 icon icon-color-1"></span>
                          <h5 className="mb-0">Easy to use</h5>
                        </div>
                        <p>Enter OTP to verify your identity.</p>
                      </div>
                      {/* <div className="single-feature mb-4">
                        <div className="d-flex align-items-center mb-2">
                          <span className="ti-alarm-clock rounded mr-3 icon icon-color-2"></span>
                          <h5 className="mb-0">Increase conversion</h5>
                        </div>
                        <p>
                          Phosfluorescently empower compelling intellectual
                          capital and revolutionary web services. Compellingly
                          develop cross-media.
                        </p>
                      </div> */}
                    </div>
                    <div className="tab-pane" id="tab6-3">
                      <div className="row">
                        <div className="col single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-check rounded mr-3 icon icon-color-2"></span>
                            <h5 className="mb-0">Verify</h5>
                          </div>
                          <p>
                            Review your company details like name, contact,
                            address, power unit and driver count.
                          </p>
                        </div>
                        {/* <div className="col single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-stats-up rounded mr-3 icon icon-color-3"></span>
                            <h5 className="mb-0">Product analytics</h5>
                          </div>
                          <p>
                            A top promo bar that counts down until a few
                            discounts.
                          </p>
                        </div> */}
                      </div>
                    </div>
                    <div className="tab-pane" id="tab6-4">
                      <p>
                        Enter email and password to complete the onboarding
                        process and start searching about the brokers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
