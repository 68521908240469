import React from "react";

const ComplaintAction = () => {
  return (
    <>
      <section
        className="video-promo ptb-100 background-img"
        style={{
          background:
            "url('assets/img/hero-bg-1.jpg') no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-8" style={{ color: "white" }}>
              <div className="section-heading text-center mb-5">
                <h2 style={{ color: "white" }}>
                  What happens when I file a complaint?
                </h2>
              </div>
              <div className="col-md-12">
                <div className="about-content-left section-heading">
                  <ul>
                    <li>
                      <span className="ti-control-record mr-2"></span>
                      We will review your complaint and send your complaint to
                      appropriate person for resolution.
                    </li>
                    <li>
                      <span className="ti-control-record mr-2"></span>
                      We will give 72 working hours for load broker to file a
                      response.
                    </li>
                    <li>
                      <span className="ti-control-record mr-2"></span>
                      You will be notified with the response if we receive one.
                    </li>
                    <li>
                      <span className="ti-control-record mr-2"></span>
                      If issue is not resolved, we will notify all members and
                      flag the involved broker as "Not Recommended".
                    </li>
                  </ul>
                </div>
              </div>
              <div className="center" style={{ paddingTop: "50px" }}>
                <a
                  href="https://app.loadbroker411.com/complaint/new"
                  className="btn outline-btn-white"
                >
                  File Complaint <span className="ti-arrow-right"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComplaintAction;
